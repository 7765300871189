/*------------------------------------------------------------------
22. Cookies
-------------------------------------------------------------------*/
.CookieMsg {
    padding: 2px 0px;
    font-size: 0.9em;
    color: #071726;
    line-height: 22px;
    border-bottom: 1px solid black;
    text-align: center;
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 100%;
    z-index: 999;
    font-family: 'Segoe UI'; }
  
  .CookieRozumiem {
    background: #fff;
    border: 2px solid #071726;
    padding: 4px 10px;
    color: #071726;
    display: inline-block;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: background 300ms ease-out;
    transition: background 300ms ease-out; }
    .CookieRozumiem:hover {
      background: #071726;
      color: #fff; }